import styled from '@emotion/styled';

import { colour, fontSize } from '../constants/style';


export const BodyText = styled.p`
  color: ${colour.primary};
  font-size: ${fontSize.body};
`;

export const HeaderText = styled.h2`
  color: ${colour.primary};
  font-size: ${fontSize.header};
`;
