import React, { Fragment } from 'react';
import 'normalize.css/normalize.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { Global } from '@emotion/core';

import { Router } from './routers/Router';
import { HeaderRouter } from './routers/HeaderRouter';
import { theme, globalStyles } from './constants/style';


const App = () => (
  <Fragment>
    <Global styles={globalStyles} />
    <ThemeProvider theme={theme}>
      <HeaderRouter />
      <Router />
    </ThemeProvider>
  </Fragment>
);

export default App;
