import React from 'react';
import { Router as ReachRouter } from '@reach/router';

import { Header } from '../components/Header';


export const HeaderRouter = () => (
  <ReachRouter>
    <Header path="/*" />
  </ReachRouter>
);
