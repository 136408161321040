import React from 'react';
import styled from '@emotion/styled';

import { colour, roundCorner } from '../constants/style';


const Background = styled.div`
  background-color: ${colour.background};
  height: 100%;
  max-width: 500px;
  margin: auto;
  margin-top: 4px;
  border: solid ${colour.secondary} 4px;
  padding: 0 5px 5px;
  ${roundCorner.all};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const Title = styled.h1`
  color: ${colour.primary};
  display: inline-block;
`;

export const Header = () => (
  <Background>
    <Title>Tarek Embree Portfolio</Title>
  </Background>
);
